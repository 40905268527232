import Link, { navigate } from "gatsby-link";
import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../components/Firebase";
import styled from "styled-components";
import LoadingWall from "../../components/LoadingWall";
import UniversitiesList from "../../helpers/universities";
import BetaList from "../../helpers/betaList";
import errorDictionary from "../../helpers/errorDictionary";
import specialExceptionList from "../../helpers/specialExceptionList";

const SignInRedirectContainer = styled.div`
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #302363;
  }
`;

const ErrorMessageText = styled.div`
  color: red;
  text-align: center;
`;

function Registration() {
  const { firebase } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstname: "",
    lastname: "",
    mailingList: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleMailingCheckbox = () =>
    setFormValues({
      ...formValues,
      mailingList: !formValues.mailingList,
    });
  let isMounted = true;

  useEffect(() => {
    //console.log(formValues);
  }, [formValues]);

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (formValues.password !== formValues.confirmPassword) {
      setErrorMessage("Password and confirm password fields must match");
      setLoading(false);
      return;
    }
    // let BetaFilteredList = BetaList.filter((element) => element.Email.toLocaleUpperCase() === formValues.email.toLocaleUpperCase())
    // //console.log()
    // if (BetaFilteredList.length === 0) {
    //   setErrorMessage("This email address is not on the beta list");
    //   setLoading(false);
    //   return;
    // }

    var parts = formValues.email.split("@");
    if (parts.length !== 2) {
      setErrorMessage("Invalid email");
      setLoading(false);
      return;
    }

    let FilteredEmailList = UniversitiesList.filter((element) =>
      element.domains.some(
        (domain) => domain.toLocaleUpperCase() === parts[1].toLocaleUpperCase()
      )
    );

    if (
      FilteredEmailList.length === 0 &&
      !specialExceptionList.includes(formValues.email) &&
      !parts[1].toLocaleUpperCase().includes(".AC.UK") &&
      !parts[1].toLocaleUpperCase().includes("NHS")
    ) {
      setErrorMessage("Please enter your university or NHS email");
      setLoading(false);
      return;
    }
    firebase
      .register({
        email: formValues.email,
        password: formValues.password,
        firstname: formValues.firstname,
        lastname: formValues.lastname,
        university: FilteredEmailList[0] || null,
        mailingList: formValues.mailingList,
      })
      .then(() => (window.location.href = "/verifyEmail"))
      .catch((error) => {
        if (isMounted) {
          //console.log(error);
          setLoading(false);
          setErrorMessage(errorDictionary[error.code]);
        }
      });
  }

  function handleInputChange(e) {
    e.persist();
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
    setErrorMessage("");
  }

  return (
    <>
      {loading && <LoadingWall />}
      <div class="page-header log-in wf-section">
        <h1 class="h1-title">
          Get OSCEing with a <u>FREE trial</u>.
        </h1>
        <p class="subheading-text">
          Register for a <span class="text-span-7">7-day free trial</span>. No
          card required. 
          <br></br>
          <br></br>
          Registration required to subscribe to a plan.
        </p>
        <div class="log-in-card">
          <div class="form-block w-form">
            <div class="text-block-5">🩺</div>
            <div class="university-text">
              Register using your university/NHS email.
            </div>
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              class="form"
              onSubmit={handleSubmit}
            >
              <input
                value={formValues.firstname}
                type="text"
                class="text-field w-input"
                maxlength="256"
                name="firstname"
                data-name="FirstName"
                placeholder="First Name"
                id="name"
                onChange={handleInputChange}
                required
              />
              <input
                value={formValues.lastname}
                type="text"
                class="text-field w-input"
                maxlength="256"
                name="lastname"
                data-name="LastName"
                placeholder="Last Name"
                id="name"
                onChange={handleInputChange}
                required
              />
              <input
                value={formValues.email}
                type="email"
                class="text-field-2 w-input"
                maxlength="256"
                name="email"
                data-name="Email"
                placeholder="Email"
                id="email"
                required
                onChange={handleInputChange}
              />
              <input
                value={formValues.password}
                type="password"
                class="text-field-3 w-input"
                maxlength="256"
                name="password"
                data-name="Password"
                placeholder="Password"
                id="Password"
                required
                onChange={handleInputChange}
              />
              <input
                value={formValues.confirmPassword}
                type="password"
                class="text-field-4 w-input"
                maxlength="256"
                name="confirmPassword"
                data-name="Password 2"
                placeholder="Confirm Password"
                id="Password-2"
                required=""
                onChange={handleInputChange}
              />
              <label class="w-checkbox checkbox-field">
                <input
                  type="checkbox"
                  id="checkbox"
                  name="termsAndConditions"
                  data-name="Checkbox"
                  class="w-checkbox-input"
                  required
                />
                <span class="w-form-label">
                  Agree to{" "}
                  <u>
                    <Link to="/terms-conditions">T&amp;Cs</Link>
                  </u>{" "}
                  and
                  <Link to="/privacy">
                    {" "}
                    <u>GDPR & privacy policy</u>
                  </Link>
                </span>
              </label>
              <label class="w-checkbox checkbox-field">
                <input
                  type="checkbox"
                  id="checkbox-2"
                  name="mailingList"
                  data-name="Checkbox 2"
                  class="w-checkbox-input"
                  onClick={handleMailingCheckbox}
                />
                <span class="w-form-label" for="checkbox-2">
                  Sign up to mailing list for exclusive discounts &amp; helpful
                  OSCE tips
                </span>
              </label>
              {!!errorMessage && (
                <div style={{ textAlign: "center" }}>
                  <ErrorMessageText>{errorMessage}</ErrorMessageText>
                  <a
                    onClick={() =>
                      (window.location = "mailto:hello@oscehub.co.uk")
                    }
                  >
                    Need help?
                  </a>
                </div>
              )}
              <input
                type="submit"
                value="Register"
                data-wait="Please wait..."
                class="button top-displacement w-button"
              />
            </form>
            <br />
            <SignInRedirectContainer onClick={() => navigate("/login")}>
              Already have an account?
            </SignInRedirectContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
