import React, { useContext } from "react";
import { FirebaseContext } from "../components/Firebase";
import Registration from "../containers/Authentication/Registration";
import Usps from "../containers/Home/Usps";
import { navigate } from "gatsby-link";

const LoginPage = () => {
  const { user } = useContext(FirebaseContext);

  user && navigate("/dashboard");
  return (
    <>
      <Registration />
      <Usps />
    </>
  );
};

export default LoginPage;
